import React from 'react'
import PropTypes from 'prop-types'

const DownloadErrorLink = ({ content = '' }) => {
  const handleDownload = () => {
    const blob = new Blob([content], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `portal_error_log_${new Date().toLocaleDateString()}.txt`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a onClick={handleDownload} href="#">
      Download Error Log
    </a>
  )
}

DownloadErrorLink.propTypes = {
  content: PropTypes.string
}

export default DownloadErrorLink
