import React from 'react'
import PropTypes from 'prop-types'

import DownloadErrorLink from 'components/buttons/DownloadErrorLink'
import { appName } from 'constants/constants'
import './index.scss'
/*
 * This is component this will shows when page will crash with error
 */
function fallBack(pageName, { error, resetErrorBoundary }) {
  const errorDetails = {
    app: appName,
    page: pageName,
    screenSize: { width: window.screen?.width, height: window.screen?.height },
    windowSize: { innerWidth: window.innerWidth, innerHeight: window.innerHeight },
    cookiesEnabled: navigator.cookieEnabled,
    platform: navigator.platform || navigator.oscpu,
    browser: navigator.userAgent,
    language: navigator.language,
    deviceMemory: navigator.deviceMemory || 'N/A',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    date: new Date().toISOString(),
    url: window.location?.href,
    error: {
      message: error.message,
      name: error.name,
      stack: error.stack
    }
  }

  return (
    <div className="error-boundary">
      <div className="error-boundary-icon" />
      <h1 className="mt-3">{`Oops! An error occurred on the ${pageName}`}</h1>
      <h2>We&apos;re sorry for the inconvenience.</h2>
      <h2>Please download the error log and send it to our support team for assistance.</h2>
      <h2>
        <DownloadErrorLink content={JSON.stringify(errorDetails, null, 2)} />
      </h2>
      <h2>
        You can try reloading the <a href={window.location.href}>previous page</a> or return <a href="/">Home</a>
      </h2>
      <div className="mt-3">
        <p>{`Error details: [${error.message}]`}</p>
        <p>{`URL: [${window.location.href}]`}</p>
      </div>
    </div>
  )
}

fallBack.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
  pageName: PropTypes.string
}

export default fallBack
