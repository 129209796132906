import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { confirmModal } from '../../actions/modal-actions'

import SingleDateInput from '../SingleDateInput'

/**
 * Returns today's noon in UTC time.
 * We need select date in UTC time but <Calendar> use local time.
 *
 * Issue:
 * If you pass a date set to midnight UTC, users in negative time zones (e.g., UTC-5) might see the date as the previous day,
 * while users in positive time zones (e.g., UTC+8) might see it as the next day.
 *
 * Solution:
 * By setting the time to noon UTC, you ensure that the DATE remains consistent across all time zones
 * when the component adjusts it to the local time.
 * Most timezones don't have shift more than 12 hours, so we can use noon as a workaround.
 */
const getTodayNoonUTC = () => {
  return moment
    .utc()
    .startOf('day')
    .add(12, 'hours')
}

class SendIncrementModalComponent extends Component {
  state = {
    selectedDate: getTodayNoonUTC()
  }

  componentDidMount() {
    this.props.onChange && this.props.onChange(this.state.selectedDate)
  }

  /**
   * @param {moment} selectedDate - date selected by the user
   */
  captureSelectedDate = selectedDate => {
    this.setState({ selectedDate })
    this.props.onChange && this.props.onChange(selectedDate)
  }

  render() {
    return (
      <div>
        <p style={{ textAlign: 'left' }}>
          {`Client checkpoint will be reset to the given date. Valid extracts
            older than this date will be scheduled to be sent. Use this option
            when you already produced the replacement extract(s) for invalid ones.`}
        </p>

        <label>
          {'Resend After: '}
          <SingleDateInput
            popupTitle="Resend After"
            selectedDate={this.state.selectedDate.toDate() || undefined}
            onDateChange={this.captureSelectedDate}
            maxDate={getTodayNoonUTC().toDate()}
          />
        </label>
        <span> this is UTC time</span>
        <p>
          In your local time this is:{' '}
          <strong>
            {moment
              .utc(this.state.selectedDate.format('YYYY-MM-DD'))
              .local()
              .format('YYYY-MM-DD hha')}
          </strong>
        </p>
      </div>
    )
  }
}

SendIncrementModalComponent.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
}

const sendIncrementModal = (updateEtlCheckpoint, feed) => {
  let inputValue = null
  return confirmModal(
    <SendIncrementModalComponent
      onChange={value => {
        inputValue = value
      }}
    />,
    'Resend Increments',
    { okButton: 'Set Checkpoint' },
    () => {
      // Convert local time to the same in UTC
      const startDayInUTC = moment.utc(inputValue.format('YYYY-MM-DD')).startOf('day')

      updateEtlCheckpoint('idf-shipping', `CHECKPOINT-${feed.id}`, startDayInUTC.toISOString())
    }
  )
}

export default sendIncrementModal
