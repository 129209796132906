import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import InfoTooltip from 'components/Tooltips/InfoTooltip'

class SelectedName extends Component {
  static propTypes = {
    onChangeMode: PropTypes.func,
    name: PropTypes.string,
    class: PropTypes.string,
    type: PropTypes.string,
    entity: PropTypes.object
  }

  getTitle = () => {
    const { entity } = this.props
    const createdAtReadable = moment(entity?.createdAt).format('YYYY-MM-DD HH:mm:ss')
    const updatedAtReadable = moment(entity?.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    return `value: ${this.props.name} 
    createdBy: ${entity?.creator?.fullname} 
    createdAt: ${createdAtReadable}
    updatedBy: ${entity?.updater?.fullname} 
    updatedAt: ${updatedAtReadable}`
  }

  render() {
    return (
      <div
        className={this.props.class}
        onDoubleClick={e => {
          e.preventDefault()
          this.props.onChangeMode({ type: this.props.type, value: 'edit' })
        }}
      >
        {this.props.name}
        <InfoTooltip text={this.getTitle()} trigger={['click']} />
      </div>
    )
  }
}

export default SelectedName
