import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeOmnisalesFilter, searchTermSuggest } from './actions/niq-search-actions'
import ChipSelector from '../ChipSelector'
import { NIQ_SEARCH_STORE_PATH } from './reducers/niq-reducers'

const OmnisalesFilter = () => {
  const dispatch = useDispatch()
  const omnisalesFilterList = useSelector(state => state[NIQ_SEARCH_STORE_PATH].searchFilters.omnisalesFilterList)

  const searchSuggestions = useSelector(state => {
    return state[NIQ_SEARCH_STORE_PATH].searchSuggestions.reduce((suggArray, suggestion) => {
      for (let i = 0; i < omnisalesFilterList.length; i++) {
        if (omnisalesFilterList[i].value === suggestion.value) {
          return suggArray
        }
      }
      suggArray.push({ ...suggestion })
      return suggArray
    }, [])
  })

  const onSearchChange = text => {
    dispatch(
      searchTermSuggest({
        term: text,
        filter: 'omnisales_module'
      })
    )
  }

  const onChangeOmnisalesFilter = filter => {
    dispatch(changeOmnisalesFilter(filter))
  }

  const renderSearchSuggestion = option => {
    return (
      <span>
        <span>{`${option.prefixLabel ? option.prefixLabel : ''}`}</span>
        <span style={{ fontWeight: 'bold', whiteSpace: 'normal' }}>{option.value}</span>
      </span>
    )
  }

  return (
    <ChipSelector
      className="category-filter-comp"
      selectedValues={omnisalesFilterList}
      onSelectionChange={onChangeOmnisalesFilter}
      onSearchChange={onSearchChange}
      searchSuggestions={searchSuggestions}
      renderSearchSuggestion={renderSearchSuggestion}
      placeholder="Select Omnisales module..."
      multiple
      showRemoveAllButton
    />
  )
}

export default OmnisalesFilter
