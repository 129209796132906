import React from 'react'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'
import cuid from 'cuid'
import './styles.scss'

const InfoTooltip = ({ text, trigger = undefined, iconClass = 'ml-2', placement = 'right', onClickHandler }) => {
  const id = cuid()
  return (
    <OverlayTrigger
      rootClose
      placement={placement}
      trigger={trigger}
      onClick={onClickHandler}
      overlay={
        <Tooltip bsStyle="scroll-y" id={id}>
          <p className="m-0 text-start" style={{ whiteSpace: 'pre-line' }}>
            {text}
          </p>
        </Tooltip>
      }
    >
      <Glyphicon glyph={`info-sign cursor-pointer  ${iconClass}`} />
    </OverlayTrigger>
  )
}

InfoTooltip.propTypes = {
  text: PropTypes.any,
  trigger: PropTypes.any,
  iconClass: PropTypes.string,
  placement: PropTypes.string,
  onClickHandler: PropTypes.func
}

export default InfoTooltip
