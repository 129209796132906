import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEqual, find, findIndex, cloneDeep, cloneDeepWith } from 'lodash'

// components
import { Button, Glyphicon, Tooltip } from 'react-bootstrap'
import SelectedName from './SelectedName'
import EditName from './EditName'
import CreateTypeaheadWrapper from './CreateTypeaheadWrapper'
import ActionItem from './ActionItem'

// actions
import { errorModal, confirmModalReport } from '../../../actions/modal-actions'
import {
  getAllGroup,
  onInputGroupChange,
  onGroupChange,
  onGroupCreate,
  fetchQueries,
  onQueryChange,
  onQueryCreate,
  onInputQueryChange,
  getPreviousQuery,
  getNextQuery,
  onDeleteGroup,
  onDeleteQuery,
  saveSearchQuery,
  onUpdatedQueryValue,
  onUpdatedGroupValue,
  onClickGroupSearch,
  onClickQuerySearch,
  updateQueryHeaderEditStatus,
  clearCompleteQuery
} from '../../../actions/search-actions'

const MODES = {
  SEARCH: 'search',
  EDIT: 'edit',
  SELECTED: 'selected'
}

class QueryHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupMode: MODES.SEARCH,
      queryMode: MODES.SEARCH
    }
  }

  componentDidMount() {
    this.props.getAllGroup()
  }

  componentDidUpdate() {
    const showSaveButton = this.shouldShowSaveButton()
    if (this.props.isQueryHeaderEdited !== showSaveButton) {
      // if values are different, update isQueryHeaderEdited in store
      this.props.updateQueryHeaderEditStatus(showSaveButton)
    }
  }

  isExist = (objList, value) => find(objList, obj => obj.name.toLowerCase() === value.toLowerCase())

  tooltip = name => <Tooltip id="in-query-tree">{name}</Tooltip>

  onGroupChange = values => {
    if (values.length && values[0].label) {
      if (values[0].type === 'create') {
        const groupExist = this.isExist(this.props.queryHeader.group.list, values[0].label)
        if (groupExist) {
          this.props.confirmModalReport(
            <p style={{ fontSize: '20px' }}>
              Group <strong>{`"${values[0].label}"`}</strong> already exists in Disabled state. Do you want to Enable
              it?
            </p>,
            { okButton: 'Yes' },
            () => {
              this.props.onGroupCreate(values[0].label)
            },
            'medium'
          )
        } else {
          this.props.onGroupCreate(values[0].label)
        }
      } else {
        this.props.onGroupChange(values)
        this.props.fetchQueries()
      }
      this.setState({ groupMode: MODES.SELECTED, queryMode: MODES.SEARCH })
    }
  }

  clearCompleteQuery = () => {
    this.props.clearCompleteQuery()
    this.setState({
      groupMode: MODES.SEARCH,
      queryMode: MODES.SEARCH
    })
    this.props.updateQueryHeaderEditStatus(false)
    this.props.ruleGroupsCountChange({})
  }

  resetQueryTree = () => {
    this.props.onClickGroupSearch()
    this.props.onClickQuerySearch()
    this.props.updateQueryHeaderEditStatus(false)
  }

  isQueryTreeDifferent = values => {
    const { query } = this.props.queryHeader
    const currentIndex = findIndex(query.list, obj => {
      return obj.name === values[0].label
    })
    if (isEqual(this.props.defaultQueryTree, this.props.queryTree)) {
      return false
    }
    return !isEqual(JSON.parse(query.list[currentIndex].query), this.props.queryTree)
  }

  onQueryChange = values => {
    if (values.length && values[0].label) {
      if (values[0].type === 'create') {
        const queryExist = this.isExist(this.props.queryHeader.query.list, values[0].label)
        if (queryExist) {
          return this.props.confirmModalReport(
            <p style={{ fontSize: '20px' }}>
              Query <strong>{`"${values[0].label}"`}</strong> already exists in Disabled state. Do you want to Enable
              it?
            </p>,
            { okButton: 'Yes' },
            () => {
              this.props.onQueryCreate(values[0].label)
              this.props.onQueryChange(values)
              this.setState({ queryMode: MODES.SELECTED })
            },
            'medium'
          )
        } else {
          this.props.onQueryCreate(values[0].label)
        }
      } else if (this.isQueryTreeDifferent(values)) {
        const message = `Are you sure to discard query tree changes ?`
        this.props.confirmModalReport(
          message,
          { okButton: 'Yes' },
          () => {
            this.props.onQueryChange(values)
          },
          'medium'
        )
      } else {
        this.props.onQueryChange(values)
      }
    }
    this.setState({ queryMode: MODES.SELECTED })
    if (this.props.queryHeader.query.list[0]) {
      this.props.ruleGroupsCountChange(JSON.parse(this.props.queryHeader.query.list[0].query))
    }
  }

  onInputGroupChange = text => {
    this.props.onInputGroupChange(text.toLowerCase())
  }

  onInputQueryChange = text => {
    this.props.onInputQueryChange(text.toLowerCase())
  }

  onDeleteGroup = () => {
    this.setState({ groupMode: MODES.SEARCH })
    this.props.onDeleteGroup()
  }

  onDeleteQuery = () => {
    const { list } = this.props.queryHeader.query
    if (list.length < 2) {
      this.setState({ queryMode: MODES.SELECTED })
    }
    this.props.onDeleteQuery()
  }

  onClickGroupDelete = () => {
    this.props.confirmModalReport(
      <p style={{ fontSize: '20px' }}>
        Are you sure you want to delete Group <strong>{`"${this.props.queryHeader.group.selectedValue}"`}</strong> ?
      </p>,
      { okButton: 'Yes' },
      () => {
        this.onDeleteGroup()
      },
      'medium'
    )
  }

  onClickQueryDelete = () => {
    this.props.confirmModalReport(
      <p style={{ fontSize: '20px' }}>
        Are you sure you want to delete Query <strong>{`"${this.props.queryHeader.query.selectedValue}"`}</strong> ?
      </p>,
      { okButton: 'Yes' },
      () => {
        this.onDeleteQuery()
      },
      'medium'
    )
  }

  onUpdatedQueryValue = event => {
    this.props.onUpdatedQueryValue(event.target.value)
  }

  onUpdatedGroupValue = event => {
    this.props.onUpdatedGroupValue(event.target.value)
  }

  validateInputField = () => {
    const {
      errorModal,
      queryHeader: { group, query }
    } = this.props
    const { groupMode, queryMode } = this.state
    if ((groupMode === MODES.EDIT && !group.updatedValue) || (queryMode === MODES.EDIT && !query.updatedValue)) {
      errorModal(`Input Field Cannot Be Empty`)
      return true
    }
  }

  validateGroupName = () => {
    const {
      errorModal,
      queryHeader: { group }
    } = this.props
    const { groupMode } = this.state
    const groupExisted = this.isExist(group.list, group.updatedValue)
    if (groupMode === MODES.EDIT && group.updatedValue !== group.selectedValue && groupExisted) {
      errorModal(`Group is already existed`)
      return true
    }
  }

  validateQueryName = () => {
    const {
      errorModal,
      queryHeader: { query }
    } = this.props
    const { queryMode } = this.state
    const queryExisted = this.isExist(query.list, query.updatedValue)
    if (queryMode === MODES.EDIT && query.updatedValue !== query.selectedValue && queryExisted) {
      errorModal(`Query is already existed`)
      return true
    }
  }

  saveSearchQuery = () => {
    if (this.validateInputField() || this.validateGroupName() || this.validateQueryName()) {
      return
    }
    this.props.saveSearchQuery()
    this.setState({ groupMode: MODES.SELECTED, queryMode: MODES.SELECTED })
  }

  handleClickOnGroupSearch = () => {
    if (this.isQueryNameChanged() || this.isQueryTreeUpdated() || this.isGroupNameChanged()) {
      const message = `Are you sure to discard your changes ?`
      return this.props.confirmModalReport(
        message,
        { okButton: 'Yes' },
        () => {
          this.onClickGroupSearch()
          this.onClickQuerySearch()
        },
        'medium'
      )
    }
    this.onClickGroupSearch()
    this.onClickQuerySearch()
  }

  onClickGroupSearch = () => {
    this.props.onClickGroupSearch()
    this.setState({ groupMode: MODES.SEARCH })
  }

  handleClickOnQuerySearch = () => {
    if (this.isQueryNameChanged() || this.isQueryTreeUpdated()) {
      const message = `Are you sure to discard your changes ?`
      return this.props.confirmModalReport(
        message,
        { okButton: 'Yes' },
        () => {
          this.onClickQuerySearch()
        },
        'medium'
      )
    }
    this.onClickQuerySearch()
  }

  onClickQuerySearch = () => {
    this.props.onClickQuerySearch()
    this.setState({ queryMode: MODES.SEARCH })
  }

  isQueryTreeUpdated = () => {
    const { currentIndex, query } = this.props.queryHeader
    return currentIndex > -1
      ? this.isQueryPayloadUpdated(JSON.parse(query.list[currentIndex].query), this.props.queryTree)
      : false
  }

  isQueryPayloadUpdated = (oldValue, newValue) => {
    return !isEqual(this.preparePayloadForComparison(oldValue), this.preparePayloadForComparison(newValue))
  }

  preparePayloadForComparison = object => {
    function omitProperties(value) {
      if (value && typeof value === 'object') {
        delete value.isMinimized
        if (value.isDisabled === undefined) {
          value.isDisabled = false
        }
      }
    }
    return cloneDeepWith(cloneDeep(object), omitProperties)
  }

  shouldShowSaveButton = () => {
    const { group, query } = this.props.queryHeader
    const { groupMode, queryMode } = this.state
    const groupExisted = this.isExist(group.list, group.updatedValue)
    if (groupMode === MODES.EDIT && group.updatedValue && !groupExisted) {
      return true
    }
    const queryExisted = this.isExist(query.list, query.updatedValue)
    if (queryMode === MODES.EDIT && query.updatedValue && !queryExisted) {
      return true
    }
    return this.isQueryTreeUpdated()
  }

  getPreviousQuery = () => {
    if (this.isQueryNameChanged() || this.isQueryTreeUpdated()) {
      const message = `Are you sure to discard your changes ?`
      return this.props.confirmModalReport(
        message,
        { okButton: 'Yes' },
        () => {
          this.props.getPreviousQuery()
        },
        'medium'
      )
    }
    this.props.getPreviousQuery()
  }

  isGroupNameChanged = () => {
    const { group } = this.props.queryHeader
    const groupExisted = this.isExist(group.list, group.updatedValue)
    if (this.state.groupMode === MODES.EDIT && group.updatedValue && !groupExisted) {
      return true
    }
    return false
  }

  isQueryNameChanged = () => {
    const { query } = this.props.queryHeader
    const queryExisted = this.isExist(query.list, query.updatedValue)
    if (this.state.queryMode === MODES.EDIT && query.updatedValue && !queryExisted) {
      return true
    }
    return false
  }

  getNextQuery = () => {
    if (this.isQueryNameChanged() || this.isQueryTreeUpdated()) {
      const message = `Are you sure to discard your changes ?`
      return this.props.confirmModalReport(
        message,
        { okButton: 'Yes' },
        () => {
          this.props.getNextQuery()
        },
        'medium'
      )
    }
    return this.props.getNextQuery()
  }

  getMode = value => {
    return value === 'search' ? MODES.SEARCH : value === 'edit' ? MODES.EDIT : MODES.SELECTED
  }

  onChangeMode = obj => {
    if (obj.type === 'group') {
      this.setState({ groupMode: this.getMode(obj.value) })
    } else if (obj.type === 'query') {
      this.setState({ queryMode: this.getMode(obj.value) })
    }
  }

  renderSelectedValue = type => (
    <SelectedName
      name={this.props.queryHeader[type].selectedValue}
      class={`qb-selected-${type}`}
      onChangeMode={this.onChangeMode}
      type={type}
    />
  )

  renderEditValue = type => (
    <EditName
      class={`${type}_name_edit`}
      value={this.props.queryHeader[type].updatedValue}
      onUpdatedValue={type === 'query' ? this.onUpdatedQueryValue : this.onUpdatedGroupValue}
      id={`tree-${type}`}
      tooltip={this.tooltip}
      onChangeMode={this.onChangeMode}
      type={type}
    />
  )

  renderSearch = type => {
    const isGroup = type === 'group'
    return (
      <div className={'sq_search_comp'}>
        <CreateTypeaheadWrapper
          class={
            this.props.queryHeader[type].selectedValue
              ? 'search_search sq_typeahead_name selected'
              : 'search_search sq_typeahead_name'
          }
          placeholder={`Select ${isGroup ? 'Group' : 'Query'}`}
          mode={isGroup ? this.state.groupMode : this.state.queryMode}
          type={type}
          selectedValue={this.props.queryHeader[type].selectedValue}
          onChange={isGroup ? this.onGroupChange : this.onQueryChange}
          onInputChange={isGroup ? this.onInputGroupChange : this.onInputQueryChange}
          currentObj={this.props.queryHeader[type]}
          maxResults={5}
        />
      </div>
    )
  }

  isNextArrowActive = () => {
    const { currentIndex, query } = this.props.queryHeader
    if (currentIndex === -1) {
      return false
    }
    for (let i = currentIndex + 1; i < query.list.length; i++) {
      if (!query.list[i].isDisable) {
        return true
      }
    }
    return false
  }

  isPreviousArrowActive = () => {
    const { currentIndex, query } = this.props.queryHeader
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (!query.list[i].isDisable) {
        return true
      }
    }
    return false
  }

  handleArrowClick = type => {
    if (type === 'right') {
      this.getNextQuery()
    } else {
      this.getPreviousQuery()
    }
  }

  renderArrow = type => {
    const isArrowActive = type === 'right' ? this.isNextArrowActive() : this.isPreviousArrowActive()
    return (
      <Button
        bsSize="xsmall"
        bsStyle="link"
        className={isArrowActive ? 'query_arrow selected' : 'query_arrow disabled'}
        onClick={() => {
          if (isArrowActive) {
            this.handleArrowClick(type)
          }
        }}
      >
        <span>
          <Glyphicon glyph={`chevron-${type}`} />
        </span>
      </Button>
    )
  }

  renderActions = type => (
    <span className={`sq_${type}_action`}>
      <ActionItem
        tooltip={this.tooltip}
        class={`${type}_search_icon`}
        iconType={'search'}
        onClick={type === 'group' ? this.handleClickOnGroupSearch : this.handleClickOnQuerySearch}
        tooltipValue={'Search'}
      />
      <ActionItem
        tooltip={this.tooltip}
        class={`${type}_delete_icon`}
        iconType={'trash'}
        onClick={type === 'group' ? this.onClickGroupDelete : this.onClickQueryDelete}
        tooltipValue={'Delete'}
      />
    </span>
  )

  renderGroupValue = () => {
    const { groupMode } = this.state
    const { group } = this.props.queryHeader
    if (groupMode === MODES.SELECTED && group.selectedValue) {
      return this.renderSelectedValue('group')
    } else if (groupMode === MODES.EDIT) {
      return this.renderEditValue('group')
    } else if (groupMode === MODES.SEARCH) {
      return this.renderSearch('group')
    }
  }

  renderQueryValue = () => {
    const { queryMode } = this.state
    const { query } = this.props.queryHeader
    if (queryMode === MODES.SELECTED && query.selectedValue) {
      return this.renderSelectedValue('query')
    } else if (queryMode === MODES.EDIT) {
      return <div className={'sq_edit_comp'}>{this.renderEditValue('query')}</div>
    } else if (!query.selectedValue || queryMode === MODES.SEARCH) {
      return this.renderSearch('query')
    }
  }

  render() {
    const { groupMode, queryMode } = this.state
    const { group, query } = this.props.queryHeader
    return (
      <div className="query-header">
        {/* Group Component Begins */}
        <div className={'sq_group_comp'}>
          <span className="qb-group-name">Query Group &nbsp;:&nbsp;</span>
          <div className={'sq_group_value'}>{this.renderGroupValue()}</div>
          {groupMode !== MODES.SEARCH && this.renderActions('group')}
        </div>
        {/* Group Component ends */}
        {/* Query Component Begins */}
        {group.selectedValue && group.inputValue && (
          <div className={'sq_query_comp'}>
            <span className="qb-query-name">Query &nbsp;:&nbsp;</span>
            {this.renderArrow('left')}
            <div className={'sq_query_value'}>{this.renderQueryValue()}</div>
            {this.renderArrow('right')}
            {queryMode !== MODES.SEARCH && query.selectedID && query.list.length > 0 && this.renderActions('query')}
          </div>
        )}
        {/* Query Component Ends */}
        {/* Header Actions Begins */}
        {group.selectedValue && (
          <div className="pull-right sq_action">
            <Button className={'sq_active_icon'} bsStyle="link" onClick={this.clearCompleteQuery}>
              Clear All
            </Button>
            {this.props.isQueryHeaderEdited && (
              <Button className={'sq_active_icon'} bsStyle="link" onClick={this.saveSearchQuery}>
                Save
              </Button>
            )}
            {this.props.isQueryHeaderEdited && (
              <Button className={'sq_active_icon'} bsStyle="link" onClick={this.resetQueryTree}>
                Reset
              </Button>
            )}
          </div>
        )}
        {/* Header Actions Ends */}
      </div>
    )
  }
}

QueryHeader.propTypes = {
  queryHeader: PropTypes.object,
  defaultQueryTree: PropTypes.object,
  getAllGroup: PropTypes.func,
  onInputGroupChange: PropTypes.func,
  onGroupChange: PropTypes.func,
  onGroupCreate: PropTypes.func,
  fetchQueries: PropTypes.func,
  onQueryChange: PropTypes.func,
  onQueryCreate: PropTypes.func,
  onInputQueryChange: PropTypes.func,
  getPreviousQuery: PropTypes.func,
  getNextQuery: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onDeleteQuery: PropTypes.func,
  saveSearchQuery: PropTypes.func,
  onUpdatedQueryValue: PropTypes.func,
  onUpdatedGroupValue: PropTypes.func,
  queryTree: PropTypes.object,
  onClickGroupSearch: PropTypes.func,
  onClickQuerySearch: PropTypes.func,
  isQueryHeaderEdited: PropTypes.bool,
  updateQueryHeaderEditStatus: PropTypes.func,
  ruleGroupsCountChange: PropTypes.func,
  clearCompleteQuery: PropTypes.func,
  errorModal: PropTypes.func,
  confirmModalReport: PropTypes.func
}

function mapStateToProps(state) {
  return {
    queryHeader: state.search.queryHeader,
    queryTree: state.search.queryTree,
    defaultQueryTree: state.search.defaultQueryTree,
    isQueryHeaderEdited: state.search.isQueryHeaderEdited
  }
}

export default connect(
  mapStateToProps,
  {
    getAllGroup,
    onInputGroupChange,
    onGroupChange,
    onGroupCreate,
    fetchQueries,
    onQueryChange,
    onQueryCreate,
    onInputQueryChange,
    getPreviousQuery,
    getNextQuery,
    onDeleteGroup,
    onDeleteQuery,
    saveSearchQuery,
    onUpdatedQueryValue,
    onUpdatedGroupValue,
    onClickGroupSearch,
    onClickQuerySearch,
    updateQueryHeaderEditStatus,
    clearCompleteQuery,
    errorModal,
    confirmModalReport
  },
  null,
  { forwardRef: true }
)(QueryHeader)
